import { PartialDeep } from 'type-fest';
import { Typography, typography } from './typography';

type MainPalette =
  | 'primary'
  | 'primary100'
  | 'primary200'
  | 'support1'
  | 'support2'
  | 'support3'
  | 'secondary0'
  | 'secondary1'
  | 'secondary2'
  | 'secondary3'
  | 'secondary4'
  | 'bright'
  | 'dark'
  | 'dark100'
  | 'neutral100'
  | 'neutral200';

type IndicatorsPalette = 'success' | 'warning' | 'error';
type TextColor = 'main' | 'contrast';
type BackgroundPalette = 'site' | 'alpha' | 'beta' | 'gamma' | 'delta';
type Breakpoints = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export interface Palette {
  main: Record<MainPalette, string>;
  indicators: Record<IndicatorsPalette, string>;
  text: Record<TextColor, string>;
  background: Record<BackgroundPalette, string>;
}

type ZIndexKeys = 'appBar' | 'drawer' | 'modal' | 'snackbar' | 'tooltip';

type Radius = 'button' | 'card' | 'circle' | 'tooltip' | 'modal';

type Border = 'checkbox' | 'dashed';

type Opacity = 'hidden' | 'half' | 'visible';

/**
 * Basic theme we will use to build components from.
 */
export interface Theme {
  palette: Palette;
  zIndex: Record<ZIndexKeys, number>;
  gutters: Gutters;
  radius: Record<Radius, number>;
  border: Record<Border, string>;
  opacity: Record<Opacity, number>;
  typography: Typography;
  breakpoints: Record<Breakpoints, number>;
}

interface Gutters {
  base: number;
}

/**
 * Components Theme which has specific overrides for specific components.
 */
interface ComponentsTheme {}

type CreateThemeOptions = { theme?: PartialDeep<Theme> };

export const createTheme: (createThemeOptions?: CreateThemeOptions) => Theme = ({ theme } = {}) => ({
  palette: {
    main: {
      primary: '#8ea0fb',
      primary100: '#626DD0',
      primary200: '#a1a7e3',
      support1: '#6377e0',
      support2: '#dce2ff',
      support3: '#f7f8ff',
      secondary0: '#6f888e',
      secondary1: '#a2c1c9',
      secondary2: '#c8dee4',
      secondary3: '#e2eef1',
      secondary4: '#f9fdfe',
      bright: '#ffffff',
      dark: '#2e4056',
      dark100: '#192348',
      neutral100: '#F9F7F3',
      neutral200: '#FCFBF7',
      ...theme?.palette?.main,
    },
    indicators: {
      success: 'green',
      warning: 'orange',
      error: 'red',
      ...theme?.palette?.indicators,
    },
    text: { main: 'black', contrast: 'white' },
    background: {
      site: '#fff',
      alpha: '#e2eef1',
      beta: '#6f888e',
      gamma: '#c8dee4',
      delta: '#ccc',
      ...theme?.palette?.background,
    },
  },
  zIndex: {
    appBar: 600,
    modal: 700,
    drawer: 800,
    snackbar: 900,
    tooltip: 1000,
    ...theme?.zIndex,
  },
  border: {
    checkbox: '1px solid #ccc',
    dashed: '1px dashed #ccc',
    ...theme?.border,
  },
  opacity: {
    hidden: 0,
    half: 0.5,
    visible: 1,
    ...theme?.opacity,
  },
  gutters: { base: 8, ...theme?.gutters },
  radius: {
    button: 20,
    card: 12,
    circle: 50,
    tooltip: 0,
    modal: 8,
    ...theme?.radius,
  },
  // typography: { ...typography(), ...(theme?.typography && {...theme.typography }) },
  typography: { ...typography() },
  breakpoints: { xs: 360, sm: 600, md: 960, lg: 1140, xl: 1920 },
});

export const theme = createTheme();
