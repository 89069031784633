import * as React from 'react';
import styled from '@emotion/styled';
import { Theme } from '@marble/theme';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * Button Variant
   * @default primary
   */
  variant?: 'primary' | 'dark' | 'dyanmic' | 'clean';

  /**
   * Optional click handler
   */
  onClick?: (e: React.MouseEvent) => void;

  gridArea?: string;

  /**
   * The size of the button
   * @default medium
   */
  size?: 'small' | 'medium' | 'large' | 'auto';

  /**
   * Should be all CAPS?
   * @default false
   */

  uppercase?: boolean;
  disabled?: boolean;
}

const StyledButton = styled('button')<
  {
    theme?: Theme;
    gridArea?: ButtonProps['gridArea'];
    variant?: ButtonProps['variant'];
    size?: ButtonProps['size'];
    uppercase?: ButtonProps['uppercase'];
  } & Pick<ButtonProps, 'disabled'>
>(
  ({ theme, variant = 'primary', gridArea, disabled, uppercase = false }) => ({
    ...theme.typography.meta.button,
    display: 'block',
    textAlign: 'center',
    borderRadius: 36,
    color: '#fff',
    cursor: 'pointer',
    outline: 'none',
    border: 'none',
    textTransform: uppercase ? 'uppercase' : 'initial',
    fontFamily: theme.typography.fontFamily[0],
    gridArea,
    width: '100%',
    // width: 'auto',
    // maxWidth: '100%',
    padding: `${theme.gutters.base * 0}px ${theme.gutters.base * 3}px`,

    ...(variant === 'primary' &&
      !disabled && {
        backgroundColor: theme.palette.main.primary,
        '&:hover': {
          backgroundColor: theme.palette.main.support1,
          boxShadow: '0 4px 16px 0 rgba(142, 160, 251, 0.59)',
        },
      }),
    ...(variant === 'dark' &&
      !disabled && {
        backgroundColor: theme.palette.main.dark,
        '&:hover': {
          color: theme.palette.main.secondary1,
          '& svg': {
            fill: theme.palette.main.secondary1,
          },
        },
      }),
    ...(variant === 'clean' && {
      backgroundColor: 'transparent',
    }),
    ...(disabled &&
      variant === 'primary' && {
        cursor: 'not-allowed',
        backgroundColor: theme.palette.main.support2,
        color: '#fff',
      }),
    ...(disabled &&
      variant === 'dark' && {
        cursor: 'not-allowed',
        backgroundColor: theme.palette.main.secondary2,
        color: '#fff',
      }),
  }),

  ({ theme, size = 'medium' }) => ({
    ...(size === 'auto' && {
      padding: 0,
    }),
    ...(size === 'small' && {
      // padding: `${theme.gutters.base * 0}px ${theme.gutters.base * 5}px`,
      padding: `${theme.gutters.base * 1}px ${theme.gutters.base * 2}px`,
      height: 40,
      // minWidth: 155,
      // maxWidth: 160,
      // minHeight: 40,
      ...theme.typography.meta.p1Bold,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      // [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      //   fontSize: 14,
      // },
    }),
    ...(size === 'medium' && {
      // padding: `${theme.gutters.base * 0}px ${theme.gutters.base * 5}px`,
      padding: `${theme.gutters.base * 0}px ${theme.gutters.base * 2}px`,
      height: 56,
      // minWidth: 280,
      // maxWidth: 240,
      // minHeight: 56,
      fontSize: 20,
      fontWeight: 700,
      lineHeight: '24px',
      // [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
      //   fontSize: 16,
      // },
    }),
    ...(size === 'large' && {
      // padding: `${theme.gutters.base * 0}px ${theme.gutters.base * 10}px`,
      padding: `${theme.gutters.base * 0}px ${theme.gutters.base * 4}px`,
      // minWidth: 335,
      // maxWidth: 280,
      height: 72,
      // minHeight: 72,
      fontSize: 24,
      fontWeight: 700,
      lineHeight: '24px',
      [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
        fontSize: 18,
      },
    }),
  }),
);

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, onClick, variant, uppercase, disabled, ...rest }, ref) => {
    return <StyledButton {...{ onClick, variant, uppercase, disabled, ref, ...rest }}>{children}</StyledButton>;
  },
);

// export const Button: React.FunctionComponent<ButtonProps> = ({
//   children,
//   onClick,
//   variant,
//   uppercase,
//   disabled,
//   ...rest
// }) => {
//   return <StyledButton {...{ onClick, variant, uppercase, disabled, ...rest }}>{children}</StyledButton>;
// };

export default Button;
