import * as React from 'react';
import styled from '@emotion/styled';
import { Theme } from '@marble/theme';

interface LogoProps {
  src: string;
  maxWidth?: number;
}

const Image = styled('img')<{ theme: Theme; maxWidth?: LogoProps['maxWidth'] }>(({ theme, maxWidth }) => ({
  display: 'block',
  width: '100%',
  maxWidth: maxWidth ?? 'initial',
}));

export const Logo: React.FunctionComponent<LogoProps> = ({ src, maxWidth }) => {
  return <Image {...{ src, maxWidth }} />;
};

export default Logo;
