import { errorsMap, handleErrors } from './../error-handling/errors';
import { appText, confirmationText, loadingText } from './../data/appText';
import { action, computed, makeObservable, observable } from 'mobx';
import StoreBase from './StoreBase';
import { CustomerDetails, StripeDescription, StripeMetadata } from '@marble/payments-types';

import {
  fetchRequestPayment,
  FetchRequestPaymentResponse,
  RequestAction,
  submitCustomerDetails,
  submitPayMethod,
  updateStatus,
} from '../api/lambdas';
import { ActionType } from '../components/FormManager/FormManager';
import { SetupIntent, StripeError } from '@stripe/stripe-js';

export type Phase =
  // 'email'  |
  'customerDetails' | 'cardDetails' | 'confirmation';

export type FieldKeys = keyof Omit<CustomerDetails, 'address'> | keyof CustomerDetails['address'];

export type Fields = Record<FieldKeys, string>;

export const pleaseEnterYour = (str: string) => `Please enter your ${str}`;

// const formSchema = object<Fields>().shape({
//   firstName: string().required(pleaseEnterYour('first name')),
//   lastName: string().required(pleaseEnterYour('last name')),
//   email: string().required(pleaseEnterYour('email')).email(),
//   phone: string().required(pleaseEnterYour('phone number')),
//   line1: string().required(pleaseEnterYour('address')),
//   city: string().required(pleaseEnterYour('city')),
//   country: string().required(pleaseEnterYour('country')),
//   state: string().required(pleaseEnterYour('state')),
//   postal_code: string().required(pleaseEnterYour('postal code')),
// });

const emptyFields: Fields = {
  // firstName: '',
  // lastName: '',
  name: '',
  email: '',
  phone: '',
  line1: '',
  line2: '',
  city: '',
  country: '',
  state: '',
  postal_code: '',
};

// const mockFields: Fields = {
//   // firstName: 'Alex',
//   // lastName: 'Raihelgaus',
//   name: 'Alex Raihelgaus',
//   email: 'alex@thesashka.com',
//   phone: '+972503068586',
//   line1: '123 Main St',
//   line2: '',
//   city: 'Santa Monica',
//   country: 'US',
//   state: 'CA',
//   postal_code: '12323',
// };

// const isFormValid = async (fields: Fields) => {
//   try {
//     const isValid = await formSchema.validate(fields, { abortEarly: false });
//     return isValid;
//   } catch (err) {
//     const formattedError = err.inner.map((error: ValidationError) => ({ key: error.path, message: error.message }));

//     return Promise.reject(formattedError);
//   }
// };

class MainStore extends StoreBase {
  // public currentPhase: Phase = 'email';
  // public currentPhase: Phase = 'customerDetails';
  public currentPhase: Phase = 'cardDetails';
  public doesUserExist: boolean = false;
  // public doesUserExist: boolean = true;
  public isLoading = true;
  public loadingText: string = '';
  public fields = emptyFields;
  public cardError: { nonClosable?: boolean; message: string } | null = null;
  public clientSecret = '';
  private requestResponse: FetchRequestPaymentResponse | null = null;
  private stripeReadiness: Record<'number' | 'cvc' | 'expiry', boolean> = {
    number: false,
    cvc: false,
    expiry: false,
  };

  // public fields = mockFields;

  // ? Using arrow functions here is mandatory.
  constructor() {
    super();
    makeObservable(this, {
      // Observables
      currentPhase: observable,
      isLoading: observable,
      fields: observable,
      doesUserExist: observable,
      // @ts-ignore
      requestResponse: observable,
      cardError: observable,
      loadingText: observable,

      // Computeds =
      requestId: computed,
      requestAction: computed,
      isCustomerDetailsValid: computed,
      metadata: computed,
      appText: computed,
      isCardSubmissionEnabled: computed,
      isStripeReady: computed,
      confirmationText: computed,

      // Actions
      start: action,
      setLoading: action,
      setFields: action,

      // submitEmail: action,
      submitCustomerDetails: action,
      setCardError: action,
      onCardSubmissionSuccess: action,
      clearCardError: action,
      handleCreatePaymentMethodError: action,
      updateStripeReadiness: action,

      // XHR
      fetchRequestPayment: action,
      submitPayMethod: action,
      updateStatusAuthorized: action,
      updateStatusCardError: action,
    });
  }

  get action(): ActionType | undefined {
    if (!this.requestResponse) {
      return undefined;
    }

    switch (this.requestResponse.action) {
      case 'strategySession': {
        return this.requestResponse.amount === '0' ? 'freeStrategy' : 'paidStrategy';
      }
      case 'cardAuthorization': {
        return 'cardAuthorization';
      }
    }
    return undefined;
  }

  get requestId(): string | null {
    return this.rootStore.routing.params.request_id;
  }

  get requestAction(): RequestAction {
    const action = (this.rootStore.routing.params.request_action as RequestAction) ?? RequestAction.LSS_MEETING;
    if (!(action in Object.keys(RequestAction))) {
      return RequestAction.LSS_MEETING;
    }
    return action;
  }

  get isCustomerDetailsValid(): boolean {
    return Boolean(
      this.fields.name &&
        // this.fields.phone &&
        // this.fields.city &&
        this.fields.country &&
        this.fields.line1 &&
        this.fields.state,
    );
  }

  get amountToCharge(): string {
    if (!this.requestResponse) {
      return '';
    }

    return `$${this.requestResponse.amount}`;
  }

  get description(): StripeDescription | undefined {
    if (!this.requestResponse) {
      return undefined;
    }
    return {
      'Practice area': this.requestResponse.practice_area,
      'Deal Id': this.requestResponse.deal_id,
    };
  }

  get appText(): { title: string; notification: string; legal: string } {
    if (!this.action) {
      return { title: '', notification: '', legal: '' };
    }

    return appText({ amountToCharge: this.amountToCharge })[this.action];
  }

  get confirmationText(): { title: string; text: string } {
    if (!this.action) {
      return { title: '', text: '' };
    }

    return confirmationText[this.action];
  }

  get isStripeReady(): boolean {
    return this.stripeReadiness.number && this.stripeReadiness.cvc && this.stripeReadiness.expiry;
  }

  get isCardSubmissionEnabled(): boolean {
    //: !stripe || !isSubmitEnabled
    return this.isStripeReady;
  }

  get stripeAccount(): string {
    return this.requestResponse?.stripeAccount || '';
  }

  public updateStripeReadiness = (key: keyof MainStore['stripeReadiness']) => () => {
    this.stripeReadiness = {
      ...this.stripeReadiness,
      [key]: true,
    };
  };
  get metadata(): StripeMetadata {
    if (!this.requestResponse) {
      return {};
    }
    return {
      deal_id: this.requestResponse.deal_id,
      hubspot_link: `https://app.hubspot.com/contacts/7324236/deal/${this.requestResponse.deal_id}`,
      description: this.requestResponse.description,
    };
  }

  public start = async () => {
    await this.fetchRequestPayment();
    this.setLoading(false);
  };

  public setLoading = (flag: boolean, text?: string) => {
    this.isLoading = flag;
    text && (this.loadingText = text);
  };

  public setFields = (fields: Fields) => {
    this.fields = fields;
  };

  public setPostalCode = (postalCode: string) => {
    this.setFields({ ...this.fields, postal_code: postalCode });
  };

  public setPhase = (phase: Phase) => {
    this.currentPhase = phase;
  };

  // public setCardError = ({ message, nonClosable }: NonNullable<MainStore['cardError']>, error?: StripeError) => {
  public setCardError = (errorMeta: MainStore['cardError'], error?: StripeError) => {
    if (!errorMeta) {
      this.cardError = {
        nonClosable: true,
        message:
          "It seems like there's an issue with the card, please try again, change a card or contact your case manager",
      };
    } else {
      this.cardError = {
        nonClosable: errorMeta.nonClosable,
        message:
          errorMeta.message ??
          "It seems like there's an issue with the card, please try again, change a card or contact your case manager",
      };
    }
  };

  public clearCardError = () => {
    this.cardError = null;
  };

  public handleCreatePaymentMethodError = (error: StripeError) => {
    const errorMeta = handleErrors(error);

    if (errorMeta) {
      this.setCardError({
        message: (errorMeta.stripeMessage || errorMeta.message) ?? 'OOps',
      });
    } else {
      this.setCardError(null, error);
    }
    this.updateStatusCardError(error);
    this.setLoading(false);
  };

  public onCardSubmissionSuccess = async (result: {
    setupIntent?: SetupIntent | undefined;
    error?: StripeError | undefined;
  }) => {
    if (result.error) {
      console.error('error', result.error);
      const errorMeta = handleErrors(result.error);

      if (errorMeta) {
        this.setCardError({
          message: (errorMeta.stripeMessage || errorMeta.message) ?? 'OOps',
        });
      } else {
        this.setCardError(null, result.error);
      }

      this.updateStatusCardError(result.error);
      this.setLoading(false);
    }
    if (!result.setupIntent) {
      return;
    }
    this.loadingText = loadingText({ amountToCharge: this.amountToCharge })[this.action ?? 'cardAuthorization'];
    await this.updateStatusAuthorized();
    await this.submitPayMethod(result.setupIntent.id);
  };

  public submitCustomerDetails = async () => {
    //   try {
    //     const isValid = await isFormValid(fields);
    //   } catch (err) {
    //     setErrors(err);
    //     setSubmitting(false);
    //     return;
    //   }
    this.setLoading(true);
    try {
      const { data } = await submitCustomerDetails(mapFieldsToStripeCustomerData(this.fields), {
        metadata: this.metadata,
        ...(this.description && { description: this.description }),
      });
      // console.log('MainStore -> publicsubmitCustomerDetails -> data', data);
      this.clientSecret = data.clientSecret;

      if (!this.clientSecret) {
        throw new Error("can't proceed");
      }
      // this.setPhase('cardDetails');
      // this.setLoading(false);
      return this.clientSecret;
    } catch (err) {
      console.error('err', err);
      this.setLoading(false);
    }
  };

  public updateStatusAuthorized = async () => {
    try {
      if (!this.requestResponse) {
        throw new Error('No requestResponse present');
      }
      await updateStatus({
        deal_id: this.requestResponse.deal_id,
        status: 'Authorized',
      });
    } catch (err) {
      console.error('err', err);
    }
  };

  public updateStatusCardError = async (error: StripeError) => {
    try {
      if (!this.requestResponse) {
        throw new Error('No requestResponse present');
      }
      await updateStatus({
        deal_id: this.requestResponse.deal_id,
        status: 'Card_Declined',
        error,
      });
    } catch (err) {
      console.error('err', err);
    }
  };

  public fetchRequestPayment = async () => {
    try {
      if (!this.requestId) {
        throw new Error('No Request ID');
      }
      const { data } = await fetchRequestPayment(this.requestId, this.requestAction);
      // @ts-ignore

      this.requestResponse = data;
      if (!this.requestResponse) {
        return;
      }

      if (data.msg && errorsMap[data.msg]) {
        this.setCardError({ message: errorsMap[data.msg], nonClosable: true });
      } else if (data.msg && data.msg.toLocaleLowerCase() !== 'ok' && !errorsMap[data.msg]) {
        this.setCardError({
          message: `Seems like there's an issue.<br />Please contact your Marble Case Manager on <br /> <a href="tel:+1(800)297-1008">+1(800)297-1008</a>`,
          nonClosable: true,
        });
      }
      // console.log('this.requestResponse.name', this.requestResponse.name);
      this.setFields({
        ...this.fields,
        email: this.requestResponse.email?.trim() ?? '',
        name: this.requestResponse.name ?? '',
        phone: this.requestResponse.phone ?? '',
      });
    } catch (err) {
      console.error('err', err);
    }
  };

  public submitPayMethod = async (setupIntentId: string) => {
    try {
      if (!this.requestId || !setupIntentId) {
        return;
      }

      const { data } = await submitPayMethod({
        request_id: this.requestId,
        setup_intent_id: setupIntentId,
        tryChargeLss: this.requestAction !== RequestAction.CHANGE_PAYMENT_METHOD,
      });

      if (data.status === 'error' && errorsMap[data.msg]) {
        this.setCardError({ message: errorsMap[data.msg], nonClosable: true });
      } else if (data.msg && data.msg.toLocaleLowerCase() !== 'ok' && !errorsMap[data.msg]) {
        this.setCardError({
          message: `Seems like there's an issue.<br />Please contact your Marble Case Manager on <br /> <a href="tel:+1(800)297-1008">+1(800)297-1008</a>`,
          nonClosable: true,
        });
      } else {
        this.setPhase('confirmation');
      }

      this.setLoading(false);
    } catch (err) {
      console.error('err', err);
    }
  };
}

export default MainStore;

const mapFieldsToStripeCustomerData = (fields: Fields): CustomerDetails => ({
  name: fields.name,
  // lastName: fields.lastName,
  phone: fields.phone,
  email: fields.email,
  address: {
    line1: fields.line1,
    line2: fields.line2,
    city: fields.city,
    state: fields.state,
    country: fields.country,
    postal_code: fields.postal_code,
  },
});
