import { observable, computed, reaction, makeObservable } from "mobx";
import { history, searchParamsToObject } from "@marble/utils";
import StoreBase from "./StoreBase";

class RoutingStore extends StoreBase {
  private search: string = window.location.search;

  constructor(externalStore?: any) {
    super(externalStore);
    history.listen((h) => {
      this.search = h.search;
    });

    reaction(
      () => this.params.companyId,
      (companyId) => {}
    );
    makeObservable(this, {
      // @ts-ignore
      search: observable,

      params: computed,
    });
  }

  public get params() {
    return searchParamsToObject(this.search);
  }

  public goBack = () => {
    history.goBack();
  };
}

export default RoutingStore;
