import { StripeError } from '@stripe/stripe-js';

export type StripeErrorObject = Record<string, Pick<StripeError, 'type' | 'code' | 'message' | 'decline_code'>>;
export const stripeErrorObject: StripeErrorObject = {};

export type OurErrorCodes =
  | 'CUSTOMER_ALREADY_EXISTS'
  | 'STRATEGY_SESSION_EXISTS'
  | 'DEAL_ALREADY_AUTHORIZED'
  | 'PAYMENT_FAILED_ON_STRIPE'
  | 'REQUEST_NOT_FOUND'
  | 'ZERO_CHARGE_ILLEGAL'
  | 'MISSING_MANDATORY_PARAMETERS';

export const errorsMap: Record<OurErrorCodes | string, string> = {
  CUSTOMER_ALREADY_EXISTS:
    'Oops... It appears that an account is already registered to this card. Please contact your Marble Case Manager on <br /> <a href="tel:+1(800)297-1008">+1(800)297-1008</a>',
  STRATEGY_SESSION_EXISTS:
    'Oops...  It appears that a strategy session is already registered to this card. Please contact your Marble Case Manager on <br /> <a href="tel:+1(800)297-1008">+1(800)297-1008</a>',
  PAYMENT_FAILED_ON_STRIPE:
    'Oops...  It appears there was a problem with processing your card. Please contact your Marble Case Manager on <br /> <a href="tel:+1(800)297-1008">+1(800)297-1008</a>',
  ZERO_CHARGE_ILLEGAL:
    'Oops... It appears there was a problem with processing your card. Please contact your Marble Case Manager on <br /> <a href="tel:+1(800)297-1008">+1(800)297-1008</a>',
  REQUEST_NOT_FOUND:
    'Oops...  It appears there was a problem with processing your card. Please contact your Marble Case Manager on <br /> <a href="tel:+1(800)297-1008">+1(800)297-1008</a>',
  DEAL_ALREADY_AUTHORIZED:
    'Oops... It appears that this transaction has already been proceeded on this card. Please contact your Marble Case Manager on <br /> <a href="tel:+1(800)297-1008">+1(800)297-1008</a>',
  MISSING_MANDATORY_PARAMETERS:
    'Oops... It appears there was a problem with processing your card. Please contact your Marble Case Manager on <br /> <a href="tel:+1(800)297-1008">+1(800)297-1008</a>',
};

// ? if code = card_decline -> need to look at decline_code
export type UserInputErrors =
  | 'invalid_card_type'
  | 'card_declined'
  | 'email_invalid'
  | 'incorrect_address'
  | 'incorrect_cvc'
  | 'incorrect_number'
  | 'incorrect_zip'
  | 'invalid_cvc'
  | 'invalid_expiry_month'
  | 'invalid_expiry_year'
  | 'invalid_number'
  | 'postal_code_invalid'
  | 'instant_payouts_unsupported'
  | 'expired_card';

const errorsThatRequireUserInput: UserInputErrors[] = [
  'invalid_card_type',
  'card_declined',
  'email_invalid',
  'incorrect_address',
  'incorrect_cvc',
  'incorrect_number',
  'incorrect_zip',
  'invalid_cvc',
  'invalid_expiry_month',
  'invalid_expiry_year',
  'invalid_number',
  'postal_code_invalid',
  'instant_payouts_unsupported',
  'expired_card',
];

// const p: StripeErrorObject = {
//   type: 'card_error' as StripeErrorType,
// };

// export const cardError: Record<UserInputErrors, Pick<StripeError, 'code' | 'message' | 'decline_code'>> = {
type CardErrorFunction = (
  error: StripeError,
) => (Pick<StripeError, 'type' | 'message' | 'decline_code' | 'code'> & { stripeMessage?: string }) | null;

export const handleErrors: CardErrorFunction = (error) => {
  const { type, code, message, decline_code } = error;

  if (type !== 'card_error' || !code) {
    return null;
  }

  if (errorsThatRequireUserInput.includes(code as UserInputErrors)) {
    // TODO - do it!
    if (code === 'card_declined') {
      return {
        type,
        code,
        decline_code,
        message: 'Card declined. Please use a different card',
      };
    } else {
      return {
        code,
        type,
        message: 'Please check your card details',
        stripeMessage: message,
      };
    }
  } else {
    return null;
  }
};
// {
//   incorrect_cvc: {
//     message: '',
//     decline_code: undefined,
//     code: 'incorrect_cvc',
//   },
// };

export type ContactSupportErrors =
  | 'payment_intent_unexpected_state'
  | 'payment_method_provider_timeout'
  | 'payment_method_unactivated'
  | 'payment_method_unexpected_state'
  | 'payment_method_provider_decline'
  | 'processing_error';
export const validationError: Record<string, string> = {};

export const contactSupport: Record<string, string> = {};

export const unknown: Record<string, string> = {};
