import * as React from 'react';
import styled from '@emotion/styled';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import {
  loadStripe,
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';

import { Button, TextField, Typography } from '@marbletech/components';
import { creditCards, cvc } from '@marble/icons';
import { Theme, theme } from '@marble/theme';
import { observer } from 'mobx-react';
import { useStore } from '../../stores/setupContext';
import MainStore from '../../stores/MainStore';
import Disclaimer from '../Disclaimer/Disclaimer';

export interface CardDetailsProps {
  clientSecret: string;
  setPhase: MainStore['setPhase'];
  setLoading: MainStore['setLoading'];
  onCardSubmissionSuccess: MainStore['onCardSubmissionSuccess'];
  cardError: MainStore['cardError'];
  updateStripeReadiness: MainStore['updateStripeReadiness'];
  setPostalCode: MainStore['setPostalCode'];
  submitCustomerDetails: MainStore['submitCustomerDetails'];
  handleCreatePaymentMethodError: MainStore['handleCreatePaymentMethodError'];
  disclaimerText: string;
  stripeAccount: string;
}

const stripeStyle = {
  borderBottom: `1px solid ${theme.palette.main.secondary1}`,
  base: {
    fontFamily: theme.typography.fontFamily[0],
    fontSize: `${theme.typography.meta.p1.fontSize}px`,
    color: theme.palette.main.dark,
    '::placeholder': {
      color: theme.palette.main.secondary1,
    },
  },
  invalid: {
    color: theme.palette.indicators.error,
  },
};

export const Form = styled('form')<{ theme: Theme }>(({ theme }) => ({
  maxWidth: theme.breakpoints.sm,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.gutters.base * 3,
  [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
    // marginTop: theme.gutters.base * 11,
  },
}));

export const CardElementsGrid = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'grid',
  gap: theme.gutters.base * 4,
  gridTemplateColumns: '1fr 1fr',
  gridTemplateAreas: `
    'number number'
    'cvc expiry' 
    'postal_code postal_code' 
    `,
  [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
    gap: theme.gutters.base * 5,
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr 1fr',
    gridTemplateAreas: `
    'number number'
    'cvc expiry' 
    'postal_code .'
    `,
  },
  // [`@media (min-height: ${700}px)`]: {
  //   rowGap: theme.gutters.base * 7,
  // },
}));

export const CardError = styled(Typography)<{ theme: Theme }>(({ theme }) => ({
  color: theme.palette.indicators.error,
  ...theme.typography.meta.p1,
  gridArea: 'error',
}));

export const CardElementWrap = styled('div')<{
  theme: Theme;
  gridArea: 'number' | 'expiry' | 'cvc' | 'postal_code';
}>(({ theme, gridArea }) => ({
  position: 'relative',
  padding: theme.gutters.base,
  borderBottom: `1px solid ${theme.palette.main.secondary1}`,
  gridArea,
  '& > img': {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
  },
}));

const ButtonWrap = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gridArea: 'button',
}));

const StyleButton = styled(Button)<{ theme: Theme }>(({ theme }) => ({
  marginTop: theme.gutters.base * 3,
  width: '100%',

  [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
    maxWidth: theme.gutters.base * 40,
  },
  [`@media (min-height: ${700}px)`]: {
    marginTop: theme.gutters.base * 5,
  },
}));

export const CardDetailsForm: React.FunctionComponent<CardDetailsProps> = ({
  clientSecret,
  onCardSubmissionSuccess,
  setLoading,
  setPostalCode,
  disclaimerText,
  updateStripeReadiness,
  submitCustomerDetails,
  handleCreatePaymentMethodError,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [fieldsState, setFieldsState] = React.useState<
    Record<
      | StripeCardNumberElementChangeEvent['elementType']
      | StripeCardCvcElementChangeEvent['elementType']
      | StripeCardExpiryElementChangeEvent['elementType'],
      { complete?: boolean; error?: string }
    >
  >({ cardNumber: {}, cardExpiry: {}, cardCvc: {} });

  const [postalCode, setLocalPostalCode] = React.useState<string>('');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    setLoading(true, 'Authorizing your payment method...');

    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);

    if (!cardNumberElement || !cardExpiryElement || !cardCvcElement) {
      return;
    }

    const clientSecret = await submitCustomerDetails();

    if (!clientSecret) {
      return;
    }

    const { error } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumberElement,
    });
    console.log('error', error);
    if (error) {
      handleCreatePaymentMethodError(error);
      return;
    }

    const result = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: cardNumberElement,
        billing_details: {},
      },
    });

    onCardSubmissionSuccess(result);
  };

  const handlePostalCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const regex = /^\d+$/;

    const isValid = regex.test(value);
    if (isValid) {
      setLocalPostalCode(value.slice(0, 5));
      setPostalCode(value);
    }
  };

  const handleCardChange = (
    event: StripeCardNumberElementChangeEvent | StripeCardCvcElementChangeEvent | StripeCardExpiryElementChangeEvent,
  ) => {
    setFieldsState({
      ...fieldsState,
      [event.elementType]: {
        error: event.error,
        complete: event.complete,
      },
    });
  };

  const isSubmitEnabled =
    fieldsState.cardNumber.complete === true &&
    fieldsState.cardExpiry.complete === true &&
    fieldsState.cardCvc.complete === true;

  return (
    <Form {...{ onSubmit: handleSubmit }}>
      <CardElementsGrid>
        <CardElementWrap {...{ gridArea: 'number' }}>
          <CardNumberElement
            {...{
              options: {
                style: stripeStyle,
              },
              onChange: handleCardChange,
              onReady: updateStripeReadiness('number'),
            }}
          />
          <img {...{ src: creditCards }} alt={'card'} />
        </CardElementWrap>
        <CardElementWrap {...{ gridArea: 'cvc' }}>
          <CardCvcElement
            {...{
              options: { style: stripeStyle },
              onChange: handleCardChange,
              onReady: updateStripeReadiness('cvc'),
            }}
          />
          <img {...{ src: cvc }} alt={'cvc'} />
        </CardElementWrap>
        <CardElementWrap {...{ gridArea: 'expiry' }}>
          <CardExpiryElement
            {...{
              options: { style: stripeStyle, placeholder: 'Exp date' },
              onChange: handleCardChange,
              onReady: updateStripeReadiness('expiry'),
            }}
          />
        </CardElementWrap>
        <TextField
          {...{
            gridArea: 'postal_code',
            placeholder: 'Zip',
            value: postalCode,
            onChange: handlePostalCode,
          }}
        />
      </CardElementsGrid>
      <Disclaimer {...{ text: disclaimerText }} />
      <ButtonWrap>
        <StyleButton
          {...{
            type: 'submit',
            variant: 'primary',
            size: 'medium',
            disabled: !stripe || !isSubmitEnabled || postalCode.length < 5,
          }}
        >
          Submit
        </StyleButton>
      </ButtonWrap>
    </Form>
  );
};

export const CardDetails: React.FunctionComponent<CardDetailsProps> = (props: CardDetailsProps) => {

  const {stripeAccount} = props;

  if (!stripeAccount) {
    return null;
  }

  return (
    <Elements {...{ stripe: loadStripe(stripeAccount)}}>
      <CardDetailsForm {...props} />
    </Elements>
  );
};

const Observed = observer(CardDetails);

const WithStoreConnection: React.FunctionComponent<Pick<CardDetailsProps, 'disclaimerText'>> = ({ disclaimerText }) => {
  const { main } = useStore();

  const {
    clientSecret,
    stripeAccount,
    setPostalCode,
    setPhase,
    onCardSubmissionSuccess,
    setLoading,
    cardError,
    updateStripeReadiness,
    submitCustomerDetails,
    handleCreatePaymentMethodError,
  } = main;

  return (
    <Observed
      {...{
        clientSecret,
        stripeAccount,
        cardError,
        setPhase,
        onCardSubmissionSuccess,
        updateStripeReadiness,
        disclaimerText,
        setLoading,
        setPostalCode,
        submitCustomerDetails,
        handleCreatePaymentMethodError,

      }}
    />
  );
};

export default observer(WithStoreConnection);
