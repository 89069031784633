import { Typography } from '@marble/ui-lib';
import * as React from 'react';
import styled from '@emotion/styled';
import { Theme } from '@marble/theme';

export interface DisclaimerProps {
  text: string;
}

export const Wrapper = styled('div')<{ theme: Theme }>(({ theme }) => ({
  gridArea: 'disclaimer',
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.gutters.base * 4,

  [`@media (min-height: ${700}px)`]: {
    marginTop: theme.gutters.base * 6,
  },
}));

export const StyledOpenClose = styled(Typography)<{ theme: Theme }>(({ theme }) => ({
  color: theme.palette.main.primary,
  cursor: 'pointer',
}));

export const Text = styled(Typography)<{ theme: Theme }>(({ theme }) => ({
  ...theme.typography.meta.caption,
  lineHeight: '16px',
  maxWidth: 327,
}));

export const Disclaimer: React.FunctionComponent<DisclaimerProps> = ({ text }) => {
  // const toggleOpen = () => setOpen(!isOpen);
  if (!text) {
    return null;
  }
  return (
    <Wrapper>
      <Text {...{ bold: true, center: true }}>{text}</Text>
      {/* <Typography {...{ bold: true, center: true }}>
        By continuing, you are agreeing that your card will be available for later charging by document sender.
        {isOpen &&
          `Don't worry, your strategy session is completely free! Your card will not be charged until your authorization.
          By providing my credit card details, I hereby authorize Marble PC to charge the credit card entered here for
          one-time and regularly scheduled charges only pursuant to a written engagement letter between us. So long as
          the amounts being charged are in accordance with the engagement letter, I agree that no prior notification
          will be required or provided before Marble PC may charge the credit card. I understand that this authorization
          will remain in effect until I cancel it in writing and I agree to notify Marble PC in writing of any changes
          in my account information or termination of this authorization at least 15 days prior to the next billing
          date. I certify that I am an authorized user of this credit card and will not distribute these scheduled
          transactions so long as the transactions correspond to the terms indicated in this authorization form and the
          engagement letter.`}
        <OpenClose {...{ onClick: toggleOpen }}> Read {isOpen ? 'less' : 'more'}.</OpenClose>
      </Typography> */}
    </Wrapper>
  );
};

export default Disclaimer;
