import * as React from 'react';
import styled from '@emotion/styled';
import { Theme } from '@marble/theme';
import { absoluteBlow } from '@marble/style-utils';

export interface InnerScrollProps {}

export const InnerScrollWrap = styled('div')<{ theme: Theme }>(({ theme }) => ({
  height: '100%',
  position: 'relative',
}));

export const InnerScrollScroll = styled('div')<{ theme: Theme }>(({ theme }) => ({
  overflowY: 'auto',
  overflowX: 'hidden',
  ...absoluteBlow,
}));

export const InnerScroll: React.FunctionComponent<InnerScrollProps> = ({ children }) => {
  return (
    <InnerScrollWrap>
      <InnerScrollScroll>{children}</InnerScrollScroll>
    </InnerScrollWrap>
  );
};

export default InnerScroll;
