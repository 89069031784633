import * as React from 'react';
import styled from '@emotion/styled';
import { Theme } from '@marble/theme';
import { Typography } from '@marble/ui-lib';
import { exclamationMark } from '@marble/icons';

export interface NotificationProps {
  text: string;
}

export const Container = styled('div')<{ theme: Theme }>(({ theme }) => ({
  backgroundColor: theme.palette.main.support2,
  borderRadius: 15,
  padding: `${theme.gutters.base * 1}px ${theme.gutters.base * 2}px`,
  display: 'flex',
  alignItems: 'center',
}));

export const Icon = styled('img')<{ theme: Theme }>(({ theme }) => ({
  display: 'block',
  marginRight: theme.gutters.base,
}));

export const Notification: React.FunctionComponent<NotificationProps> = ({ text }) => {
  return (
    <Container>
      <Icon {...{ src: exclamationMark }} /> <Typography {...{ variant: 'caption', bold: true }}>{text}</Typography>
    </Container>
  );
};

export default Notification;
