import * as React from 'react';
import styled from '@emotion/styled';
import { ColorKey, colorSelect, Theme } from '@marble/theme';

export interface SVGIconProps {
  width?: number;
  height?: number;
  viewBox?: string;
  color?: ColorKey;
  onClick?: () => void

  /**
   * Should icon rotate? If yes, by how many degrees, relies on rotate(_deg)
   *
   */
  rotate?: number;
}

export const SVGIconBase = styled('svg')<{ theme: Theme } & Pick<SVGIconProps, 'rotate'>>(({ theme, rotate }) => ({
  display: 'inline-flex',
  ...(rotate && {
    transform: `rotate(${rotate}deg)`,
  }),
}));

export const SVGIconWrap = styled('div')<{
  theme: Theme;
  height: number;
  width: number;
}>(({ theme, height, width }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height,
  width,
  cursor: 'pointer',
}));

export const SVGIcon: React.FunctionComponent<SVGIconProps> = ({
  viewBox,
  height,
  width,
  children,
  color = 'dark',
  ...rest
}) => {
  return (
    <SVGIconBase
      {...{
        height,
        width,
        fill: colorSelect(color),
        viewBox: viewBox || `0 0 24 24`,
        xmlns: 'http://www.w3.org/2000/svg',
        xmlnsXlink: 'http://www.w3.org/1999/xlink',
        ...rest,
      }}
    >
      {children}
    </SVGIconBase>
  );
};

export default SVGIcon;
