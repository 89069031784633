import { PartialDeep } from 'type-fest';
import { RootStore } from './RootStore';

import MainStore from './MainStore';
import RoutingStore from './RoutingStore';
// import ErrorsStore from './errors';
// import PopupsStore from './popup';

export interface Stores {
  main: MainStore;
  routing: RoutingStore;
}

export type DeepParitalStores = PartialDeep<Stores>;

export const createStore = (externalStores?: DeepParitalStores) => {
  return new RootStore<Stores>({
    main: new MainStore(),
    routing: new RoutingStore(),
  });
};
