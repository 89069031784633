import React from "react";
import { ThemeProvider } from "emotion-theming";
import { theme as defaultTheme, Theme } from "@marble/theme";

interface WithThemeProps {
  theme?: Theme;
}

export const WithTheme: React.FunctionComponent<WithThemeProps> = ({
  theme = defaultTheme,
  children,
}) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
