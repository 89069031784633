import {StripeError} from '@stripe/stripe-js';
import axios, {AxiosResponse} from 'axios';
import {StripeDescription, StripeMetadata} from '@marble/payments-types/CustomerDetails';

const getTopology = () => {
    const { origin } = window.location;
    switch (origin) {
        case 'https://payments.marble.co':
        case 'https://payments.themarbleway.com':
            return {
                env: 'production',
                baseUrl: 'https://api.marble.co/graphql',
            };

        case 'https://staging-payments.themarbleway.com':
        case 'https://staging-payments.marble.co':
            return {
                env: 'staging',
                baseUrl: 'https://stg-api.marble.co/graphql',
            };

        default:
            return {
                env: 'development',
                baseUrl: 'http://localhost:3400/graphql',
            };
    }
};


export const getBaseUrl = () => {
    const { baseUrl } = getTopology();
    return baseUrl;
};

export const getEnv = () => {
    const { env } = getTopology();
    return env;
};


export interface CustomerDetails {
    address: {
        line1: string;
        line2: string;
        city: string;
        country: string;
        postal_code: string;
        state: string;
    };
    phone: string;
    email: string;
    name: string;
    // firstName: string;
    // lastName: string;
}

export interface FetchRequestPaymentResponse {
    deal_id: string;
    name: string;
    email: string;
    practice_area: 'Employment';
    phone: string;
    action: PaymentAction;
    amount: string;
    attorney: string;
    description: string;
    status: 'success' | 'error';
    msg?: string;
    stripeAccount: string;
}

export type PaymentAction = 'strategySession' | 'cardAuthorization';

export enum RequestAction {
    LSS_MEETING = '0',
    CHANGE_PAYMENT_METHOD = '1',
}

export const fetchRequestPayment = async (requestId: string, requestAction: RequestAction): Promise<AxiosResponse<FetchRequestPaymentResponse>> => {
    const baseUrl = getBaseUrl();

    return await axios({
        url: baseUrl,
        method: 'post',
        data: {
            query: `
            query GetOpportunityPaymentData($input: PaymentInput!) {
            getOpportunityPaymentData(input: $input) {
                deal_id
                name
                email
                phone
                practice_area
                action
                action_text
                amount
                description
                ccAuthorized
                stripeId
                stripeAccount
                      }
            }`,
            variables: {
                input: {
                    opportunityId: requestId,
                    requestAction: requestAction,
                }
            }
        }
    }).then((result) => {
        if (!result.data.data) {
            return {
                data: {
                    status: 'error',
                    msg: 'failed to complete payment',
                }
            } as any;
        }
        return { data: result.data.data.getOpportunityPaymentData };
    }).catch((e) => {
        return {
            data: {
                status: 'error',
                msg: 'failed to complete payment',
            }
        } as any;
    });
}

export interface UpdateStatusRequest {
    deal_id: string;
    error?: StripeError;
    status: 'Authorized' | 'Card_Declined';
}

export interface UpdateStatusResponse {
    message: 'OK';
}

export const updateStatus = async (payload: UpdateStatusRequest) => {
  // Legacy function, dont' invoke api
};

export interface SubmitPayMethodRequest {
    request_id: string;
    setup_intent_id: string;
    tryChargeLss?: boolean;
}

export interface SubmitPayMethodResponse {
    msg: string;
    status: 'success' | 'error';
}

export const submitPayMethod = async (payload: SubmitPayMethodRequest) => {
    const baseUrl = getBaseUrl();
    return await axios({
        url: baseUrl,
        method: 'post',
        data: {
            query: `mutation AddPayment($input: AddPaymentInput!) {
	                    addPayment(input: $input) 
                	}
                `,
            variables: {
                input: {
                    opportunityId: payload.request_id,
                    setupIntentId: payload.setup_intent_id,
                    tryChargeLss: payload.tryChargeLss,
                }
            }
        }
    }).then((result) => {
        if (!result.data.data) {
            return {
                data: {
                    status: 'error',
                    msg: 'failed to complete payment',
                }
            } as any;
        }
        return {data: { success: result.data.data.addPayment }};
    }).catch((e) => {
        return {
            data: {
                status: 'error',
                msg: 'failed to complete payment',
            }
        } as any;
    });
};

interface CustomerDetailsResponse {
    clientSecret: string;
}

export const submitCustomerDetails = async (
    payload: CustomerDetails,
    {metadata, description}: { metadata?: StripeMetadata; description?: StripeDescription },
) => {
    const baseUrl = getBaseUrl();

    const graphDescription = {
        practiceArea: !description ? null : description["Practice area"],
        deal_id: !description ? null : description["Deal Id"],
    }
    return await axios({
        url: baseUrl,
        method: 'post',
        data: {
            query: `
                    mutation RegisterStripeCustomer($input: RegisterStripeCustomerInput!) {
	                    registerStripeCustomer(input: $input) {
	                     clientSecret
	                     }
                	}`,
            variables: {
                input: {
                    customerDetails: payload,
                    metadata: metadata,
                    description: graphDescription,
                }
            }
        }
    }).then((result) => {
        if (!result.data.data) {
            return {
                data: {
                    status: 'error',
                    msg: 'failed to complete payment',
                }
            } as any;
        }
        return {
            data: {
                clientSecret: result.data.data.registerStripeCustomer.clientSecret
            },
        }
    }).catch((e) => {
        return {
            data: {
                status: 'error',
                msg: 'failed to complete payment',
            }
        } as any;
    })
};
