import * as React from 'react';
import styled from '@emotion/styled';
import { Theme } from '@marble/theme';
import Tooltip from '../Tooltip/Tooltip';
import TextField from '../TextField/TextField';

export interface Option {
  key: string | number;
  value: string;
  label: string;
}

interface SelectProps {
  options: Option[];
  label: string;
  // inputComponent?: React.ReactNode;
  inputComponent?: React.ReactElement;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSelect: (option: Option, ind: number) => void;
  defaultValue?: string;
  disabled?: boolean;
}

const StyledSelect = styled('ul')<{ theme?: Theme }>(({ theme }) => ({
  //   ...theme.fonts.meta.body2,
  margin: 0,
  padding: 0,
  listStyle: 'none',
}));

const StyledOption = styled('li')<{ theme?: Theme }>(({ theme }) => ({
  ...theme.typography.meta.p1,
  color: theme.palette.main.dark,
  padding: `${theme.gutters.base}px ${theme.gutters.base * 2}px`,
  background: theme.palette.background.site,
  cursor: 'pointer',
  '&:not(:last-child)': {
    borderBottom: `1px solid ${theme.palette.background.gamma}`,
  },
  '&:hover': {
    backgroundColor: theme.palette.background.alpha,
  },
}));

export const Selected = styled(TextField)<{ theme: Theme }>(({ theme }) => ({
  cursor: 'pointer',
}));

export const Select: React.FunctionComponent<SelectProps> = ({
  onChange,
  onSelect,
  options,
  defaultValue,
  inputComponent,
  disabled,
  label,
}) => {
  const [selectedIndex, setSelectedIndex] = React.useState<number>(
    defaultValue ? options.findIndex((opt) => opt.value === defaultValue) : 0,
  );

  const handleClick = (e: any) => {
    const ind = e.target.value;

    setSelectedIndex(e.target.value);
    onSelect(options[ind], ind);
  };
  // onChange(options[ind], ind);

  return (
    <Tooltip
      {...{
        button: inputComponent ? (
          inputComponent
        ) : (
          <div>
            <Selected {...{ disabled: true, value: options[selectedIndex]?.label, onChange: () => {} }} />
          </div>
        ),
      }}
    >
      <StyledSelect {...{ onClick: disabled ? () => {} : handleClick }}>
        {options.map((option, ind) => {
          return <StyledOption {...{ key: option.key, value: ind }}>{option.label}</StyledOption>;
        })}
      </StyledSelect>
    </Tooltip>
  );
};

export default Select;
