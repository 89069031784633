import { ActionType } from '../components/FormManager/FormManager';
import MainStore from '../stores/MainStore';

export const appText: (data: { amountToCharge?: string }) => Record<ActionType, MainStore['appText']> = ({
  amountToCharge,
}) => ({
  freeStrategy: {
    title: 'FREE attorney strategy session',
    notification: 'You won’t be charged unless you authorize a payment',
    legal:
      'By continuing, you agree that your card is available for charging. You will not becharged anything, unless you give us your permission and approval.',
  },
  paidStrategy: {
    title: `Confirming your ${amountToCharge} attorney strategy session`,
    notification: '',
    legal:
      'Please provide your credit card details so we can process payment for your session.By continuing, you agree that your card is available for charging.',
  },
  cardAuthorization: {
    title: 'Authorize your payment method',
    notification: 'You won’t be charged unless you authorize a payment',
    legal:
      'By continuing, you agree that your card is available for charging. You will not becharged anything, unless you give us your permission and approval.',
  },
});

export const confirmationText: Record<ActionType, MainStore['confirmationText']> = {
  cardAuthorization: {
    title: 'Thank you for confirming your payment method!',
    text: 'Your credit card authorization was successful! We will update you soon with the next step in the process.',
  },
  paidStrategy: {
    title: 'Your payment has been confirmed!',
    text:
      'Next step in the process is to attend your strategy session with your attorney. You will receive an email confirming your scheduled time.',
  },
  freeStrategy: {
    title: 'Your payment has been confirmed!',
    text: 'Your credit card authorization was successful! We will update you soon with the next step in the process.',
  },
};

export const loadingText: (data: { amountToCharge?: string }) => Record<ActionType, string> = ({ amountToCharge }) => ({
  cardAuthorization: 'Your payment method was authorized!',
  paidStrategy: `Charging your card ${amountToCharge}...`,
  freeStrategy: 'Your payment method was authorized!',
});
