import * as React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@marble/ui-lib';
import { Theme } from '@marble/theme';

export interface SecureProps {}

const Lock: React.FunctionComponent = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-150 -164) translate(150 160) translate(0 4)">
              <rect width="12" height="10" y="6" fill="#2E4056" rx="2" />
              <path
                stroke="#2E4056"
                strokeWidth="2"
                d="M6 1c.966 0 1.841.392 2.475 1.025C9.108 2.66 9.5 3.534 9.5 4.5h0V7h-7V4.5c0-.966.392-1.841 1.025-2.475C4.16 1.392 5.034 1 6 1z"
              />
              <rect width="3" height="4" x="4.5" y="9.333" fill="#FFF" rx="1.5" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const Container = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: 141,
}));

export const Secure: React.FunctionComponent<SecureProps> = () => {
  return (
    <Container>
      <Lock />
      <Typography {...{ variant: 'caption' }}>Secure and confidential</Typography>
    </Container>
  );
};

export default Secure;
