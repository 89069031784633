export type TypographyVariant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h4SemiBold'
  | 'p1'
  | 'p1Bold'
  | 'p1SemiBold'
  | 'p2'
  | 'p2SemiBold'
  | 'p2Bold'
  | 'subtitle1'
  | 'subtitle2'
  | 'button'
  | 'caption'
  | 'captionBold';

interface TypoMeta
  extends Pick<
    React.CSSProperties,
    | 'fontSize'
    | 'fontWeight'
    | 'lineHeight'
    | 'letterSpacing'
    | 'textTransform'
    | 'textDecoration'
    | 'textAlign'
    | 'color'
  > {}

export interface Typography {
  imports: string[];
  fontFamily: string[];
  meta: Record<TypographyVariant, TypoMeta>;
}

export const typography = (): Typography => ({
  imports: [
    'https://fonts.googleapis.com/css2?family=Assistant:wght@400;600;700&family=Playfair+Display:wght@400;600;700&display=swap',
  ],
  fontFamily: [`'Assistant', sans-serif`, `'Playfair Display', serif`],
  meta: {
    h1: {
      fontSize: 80,
      fontWeight: 700,
      lineHeight: '96px',
    },
    h2: {
      fontSize: 56,
      fontWeight: 700,
      lineHeight: '64px',
    },
    h3: {
      fontSize: 32,
      lineHeight: '40px',
      fontWeight: 700,
    },
    h4: {
      fontSize: 24,
      lineHeight: '32px',
      fontWeight: 700,
    },
    h4SemiBold: {
      fontSize: 24,
      lineHeight: '32px',
      fontWeight: 600,
    },
    p1: {
      fontSize: 16,
      lineHeight: '24px',
    },
    p1SemiBold: {
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 600,
    },
    p1Bold: {
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 700,
    },
    p2: {
      fontSize: 18,
      lineHeight: '24px',
    },
    p2SemiBold: {
      fontSize: 18,
      lineHeight: '24px',
      fontWeight: 600,
    },
    p2Bold: {
      fontSize: 18,
      lineHeight: '24px',
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: 24,
      lineHeight: '32px',
      fontWeight: 600,
    },
    subtitle2: {
      fontSize: 24,
      lineHeight: '24px',
    },
    caption: {
      fontSize: 12,
      lineHeight: '12px',
      fontWeight: 600,
    },
    captionBold: {
      fontSize: 12,
      lineHeight: '16px',
      fontWeight: 700,
    },
    button: {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: '24px',
    },
  },
});
