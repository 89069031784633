import * as React from 'react';
import styled from '@emotion/styled';
import { Theme } from '@marble/theme';
import { usePopper } from 'react-popper';
import ClickAwayListener from './../ClickAwayListener/ClickAwayListener';
import { motion, AnimatePresence } from 'framer-motion';

export interface TooltipProps {
  onClose?: () => void;
  toggleFunc?: () => void;
  button?: React.ReactElement;
  open?: boolean;
  placement?: 'right' | 'top' | 'left' | 'bottom' | 'bottom-start';
  /** @default true */
  selfClosing?: boolean;
  anchorEl?: any; // React.RefObject;
  strategy?: 'absolute' | 'fixed';
}

export const Popper = styled(motion.div)<{ theme: Theme }>(({ theme }) => ({
  label: 'popper',
  zIndex: 1,
  maxHeight: theme.gutters.base * 30,
  overflowX: 'hidden',
  overflowY: 'auto',
}));
export const Card = styled('div')<{ theme: Theme }>(({ theme }) => ({
  //   width: '100%',
  label: 'card',
  boxShadow: '0 12px 32px 0 rgba(111, 136, 142, 0.13)',
  '& li': {
    ...theme.typography.meta.p1SemiBold,
    color: theme.palette.main.dark,
    fontFamily: theme.typography.fontFamily[0],
  },
  '& li:hover': {
    backgroundColor: theme.palette.main.support2,
  },
}));

export const Tooltip: React.FunctionComponent<TooltipProps> = ({
  placement = 'bottom-start',
  // placement = 'bottom',
  toggleFunc,
  button,
  open,
  children,
  selfClosing = true,
  anchorEl,
  strategy = 'absolute',
}) => {
  const [buttonRef, setButtonRef] = React.useState(null);
  const [tooltipRef, setTooltipRef] = React.useState(null);

  const isControlled = typeof open === 'boolean';

  const [show, setShow] = React.useState<boolean>(isControlled ? Boolean(open) : false);

  const { styles, attributes } = usePopper(buttonRef || anchorEl, tooltipRef, {
    placement,
    strategy,
  });

  React.useEffect(() => {
    if (!buttonRef || !tooltipRef) {
      return;
    }
    isControlled && setShow(Boolean(open));
  }, [open]);

  const visible = isControlled ? Boolean(open) : show;

  const handleClick = () => {
    isControlled && toggleFunc && toggleFunc();
    !isControlled && setShow(!show);
  };

  const onClickAway = () => {
    isControlled && toggleFunc && toggleFunc();
    !isControlled && selfClosing && setShow(false);
    setShow(false);
  };

  if (!button && !anchorEl) {
    return null;
  }

  return (
    <div
      {...{
        style: {
          position: 'relative',
          display: 'flex',
        },
      }}
    >
      <ClickAwayListener {...{ onClickAway }}>
        {button &&
          React.cloneElement(button, {
            ref: setButtonRef,
            type: 'button',
            onClick: handleClick,
            style: { cursor: 'pointer', display: 'flex' },
          })}
        <AnimatePresence>
          {visible && (
            // @ts-ignore
            <Popper
              {...{
                transition: { duration: 0.2 },
                initial: { opacity: 0 },
                exit: { opacity: 0 },
                animate: { opacity: 1 },
                ref: setTooltipRef,
                style: {
                  ...styles.popper,
                  boxShadow: '0 12px 32px 0 rgba(111, 136, 142, 0.13)',
                  minWidth: '100%',
                  maxHeight: 'initial',
                },
                ...attributes.popper,
              }}
            >
              <Card
                {...{
                  rounded: true,
                  isSelected: true,
                  onClick: (e: any) => {
                    e.stopPropagation();
                    selfClosing && setShow(false);
                  },
                }}
              >
                {children}
              </Card>
            </Popper>
          )}
        </AnimatePresence>
      </ClickAwayListener>
    </div>
  );
};

export default Tooltip;
