import React from 'react';
import styled from '@emotion/styled';
import './App.css';
import { Theme } from '@marble/theme';
import FormManager from './components/FormManager/FormManager';

const Wrapeer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  minHeight: '100vh',
  padding: theme.gutters.base * 2,
  [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
    padding: `80px 10%`,
  },
}));

function App() {
  return (
    <div className="App">
      <Wrapeer>
        <FormManager />
      </Wrapeer>
    </div>
  );
}

export default App;
