import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

export const searchParamsToObject = (search: string): { [param: string]: string } => {
  const result: { [param: string]: string } = {};
  // @ts-ignore
  // for (const entry of new URLSearchParams(search)) {
  for (const entry of new URLSearchParams(search).entries()) {
    const [key, value] = entry;
    result[key] = value;
  }

  return result;
};

export const navigate = (pathname?: string, params?: { [param: string]: string }, preserveParams?: boolean) => {
  const existingParams = preserveParams ? searchParamsToObject(history.location.search) : {};
  history.push({
    pathname,
    search: new URLSearchParams({ ...existingParams, ...params }).toString(),
  });
};
