import { Palette, theme } from './theme';

export type ColorKey =
  | keyof Palette['main']
  | keyof Palette['text']
  | keyof Palette['indicators']
  | keyof Palette['background']
  | 'inherit';

export const colorSelect = (color: ColorKey): string => {
  if (!color) {
    return theme.palette.text.main;
  }

  if (color === 'inherit') {
    return 'inherit';
  }

  if (color in theme.palette.main) {
    //@ts-ignore
    return theme.palette.main[color];
  } else if (color in theme.palette.text) {
    //@ts-ignore
    return theme.palette.text[color];
  } else if (color in theme.palette.indicators) {
    //@ts-ignore
    return theme.palette.indicators[color];
  } else if (color in theme.palette.background) {
    //@ts-ignore
    return theme.palette.background[color];
  }

  return theme.palette.text.main;
};
