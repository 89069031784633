import * as React from 'react';
import styled from '@emotion/styled';
import { Theme } from '@marble/theme';
import { motion, AnimatePresence } from 'framer-motion';
import { PlusIcon } from '@marble/icons';

export interface AccordionProps {
  children: React.ReactNode;
  expandIcon?: React.ReactNode;
}

export const AccordionDetails: React.FunctionComponent<AccordionProps> = ({ children }) => {
  return <div>{children}</div>;
};

export const AccordionSummary: React.FunctionComponent<AccordionProps> = ({ children }) => {
  return <div>{children}</div>;
};

export const AccordionWrap = styled(motion.div)<{ theme: Theme; isExpanded: boolean }>(({ theme, isExpanded }) => ({
  cursor: 'pointer',
  backgroundImage: `linear-gradient(90deg, #E2EEF1 0%, #F9FDFE 100%)`,
  padding: `${theme.gutters.base * 2.5}px 0`,
  ...(isExpanded && {
    paddingBottom: theme.gutters.base * 5,
  }),
  paddingLeft: theme.gutters.base * 4,
  paddingRight: theme.gutters.base * 3,
  transition: '.3s',
  '&:not(:last-of-type)': {
    marginBottom: theme.gutters.base * 4,
  },
  '&:hover': {
    boxShadow: `0 0 0 2px ${theme.palette.main.secondary1}`,
  },
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    ...(isExpanded && {
      paddingBottom: theme.gutters.base * 4,
    }),
  },
}));

export const ExpandIcon = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const SummaryWrap = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const IconWrap = styled(motion.div)<{ theme: Theme; isExpanded: boolean }>(({ theme, isExpanded }) => ({
  display: 'inline-flex',
  transform: isExpanded ? 'rotate(180deg)' : 'none',
  alignItems: 'center',
  marginLeft: theme.gutters.base * 1.5,
}));

export const DetailsWrap = styled(motion.div)<{ theme: Theme; isExpanded?: boolean }>(({ theme, isExpanded }) => ({
  paddingTop: theme.gutters.base * 1.5,
  boxSizing: 'border-box',
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    paddingTop: theme.gutters.base * 3.5,
  },
}));

export const Accordion: React.FunctionComponent<AccordionProps> = ({ children, expandIcon }) => {
  const [summary, details] = React.Children.toArray(children);
  const [isExpanded, setExpanded] = React.useState<boolean>(false);
  return (
    <AccordionWrap {...{ layout: true, onClick: () => setExpanded(!isExpanded), isExpanded }}>
      <SummaryWrap>
        {summary}
        <IconWrap {...{ isExpanded, animate: { rotateZ: isExpanded ? 45 : 0 } }}>
          <ExpandIcon {...{ isExpanded }}>{expandIcon ?? <PlusIcon {...{ color: 'secondary1' }} />}</ExpandIcon>
        </IconWrap>
      </SummaryWrap>
      <motion.div
        {...{
          key: 'content',
          isExpanded,
          initial: 'closed',
          animate: isExpanded ? 'open' : 'closed',
          exit: 'closed',
          variants: {
            open: {
              opacity: 1,
              height: 'auto',
            },
            closed: {
              opacity: 1,
              height: 0,
            },
          },
          transition: {
            duration: 0.8,
            ease: [0.04, 0.62, 0.23, 0.98],
          },
        }}
      >
        <AnimatePresence
          {...{
            initial: false,
          }}
        >
          {isExpanded && (
            <DetailsWrap
              {...{
                style: {
                  transformOrigin: 'top center',
                },
                layout: true,
                initial: 'collapsed',
                animate: isExpanded ? 'expanded' : 'collapsed',
                variants: {
                  expanded: {
                    scale: 1,
                    opacity: 1,
                  },
                  collapsed: {
                    scale: 1,
                    opacity: 0,
                    transition: {},
                  },
                },
              }}
            >
              {details}
            </DetailsWrap>
          )}
        </AnimatePresence>
      </motion.div>
    </AccordionWrap>
  );
};

export default Accordion;
