import * as React from 'react';
import styled from '@emotion/styled';
import { theme, Theme } from '@marble/theme';
import { P1, P1Bold } from '@marbletech/components';
import { loading } from '@marble/icons';
import { TempLoader } from '../TempLoader';

type LoaderSize = 'xlarge' | 'medium';

export interface LoadingProps {
  text?: string;
  size?: LoaderSize;
}

const sizes = {
  xlarge: {
    maxWidth: 300,
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      maxWidth: 160,
    },
  },
  medium: {
    maxWidth: 120,
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      maxWidth: 80,
    },
  },
};

const Img = styled('img')<{ theme: Theme; size?: LoaderSize }>((props) => ({
  display: 'block',
  width: '100%',
  height: 'auto',
  margin: '0 auto',
  ...sizes[props.size ?? 'xlarge'],
}));

export const Wrap = styled('div')<{ theme: Theme; position?: 'fixed' | 'absolute' }>(({ position = 'absolute' }) => ({
  backgroundColor: 'rgba(249, 253, 254,0.88)',
  position,
  zIndex: 1000,
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}));

export const Loading: React.FunctionComponent<LoadingProps> = ({ size }) => {
  return <Img src={loading} size={size} />;
};

export const LoadingAbsolute: React.FunctionComponent<LoadingProps> = ({ text, size }) => {
  return (
    <Wrap>
      <div>
        <TempLoader />
        {text && <P1>{text}</P1>}
      </div>
    </Wrap>
  );
};

export const LoadingFullPage: React.FunctionComponent<LoadingProps> = ({ text, size }) => {
  return (
    <Wrap position={'fixed'}>
      <TempLoader />
      {text && <P1Bold>{text}</P1Bold>}
    </Wrap>
  );
};

export default Loading;
