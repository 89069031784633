import * as React from 'react';
// import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import MainStore from '../../stores/MainStore';
import { useStore } from '../../stores/setupContext';
import { Dialog, Typography } from '@marble/ui-lib';

export interface StripeErrorDialogProps {
  cardError: NonNullable<MainStore['cardError']>;
  clearCardError: MainStore['clearCardError'];
}

export const StripeErrorDialog: React.FunctionComponent<StripeErrorDialogProps> = ({ cardError, clearCardError }) => {
  return (
    <Dialog
      {...{
        title: 'Something is wrong',
        content: cardError.message ? (
          <Typography
            {...{ variant: 'p1', center: true, bold: true }}
            {...{
              dangerouslySetInnerHTML: { __html: `${cardError.message}` },
            }}
          />
        ) : (
          'Please contact your case manager'
        ),
        ...(!cardError.nonClosable && {
          confirmationButton: {
            text: 'OK',
            action: clearCardError,
          },
        }),
      }}
    />
  );
};

const Observed = observer(StripeErrorDialog);

const WithStoreConnection = () => {
  const { main } = useStore();

  const { cardError, clearCardError } = main;

  if (!cardError) {
    return null;
  }

  return <Observed {...{ cardError, clearCardError }} />;
};

export default observer(WithStoreConnection);
