import React from 'react';
import styled, { keyframes } from 'styled-components';

const animation = keyframes`
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
`;

const SIZE = 80;

const Loader = styled.div`
  width: ${SIZE}px;
  height: ${SIZE}px;
  position: relative;

  &:after,
  &:before {
    transform: scale(0);
    content: '';
    box-sizing: border-box;
    width: ${SIZE}px;
    height: ${SIZE}px;
    border-radius: 50%;
    background: ${(props) => props.theme.global.colors.primary200};
    position: absolute;
    left: 0;
    top: 0;
    animation: ${animation} 2s linear infinite;
  }
  &:after {
    animation-delay: 1s;
  }
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const TempLoader = () => {
  return (
    <Container>
      <Loader />
    </Container>
  );
};
