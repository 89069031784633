import * as React from 'react';
import styled from '@emotion/styled';
import CardDetails from '../CardDetails/CardDetails';
import { observer } from 'mobx-react';
import { useStore } from '../../stores/setupContext';
import MainStore from '../../stores/MainStore';
import { Logo, Typography } from '@marbletech/components';
import { Theme } from '@marble/theme';
import StripeErrorDialog from '../StripeErrorDialog/StripeErrorDialog';
import ConfirmationPage from '../ConfirmationPage/ConfirmationPage';
import { LoadingFullPage } from '../Loading/Loading';
import Secure from '../Secure/Secure';
import Notification from '../Notification/Notification';

export interface FormManagerProps {
  currentPhase: MainStore['currentPhase'];
  // action: MainStore['action'];
  // amountToCharge: MainStore['amountToCharge'];
  appText: MainStore['appText'];
  isCardSubmissionEnabled: MainStore['isCardSubmissionEnabled'];
  isLoading: boolean;
  loadingText: MainStore['loadingText'];
  stripeAccount: MainStore['stripeAccount'];
}

export const Wrap = styled('div')<{ theme: Theme }>(({ theme }) => ({
  label: 'wrap',
  width: '100%',
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateAreas: `
  'intro'
  'phase'
  `,
  gap: theme.gutters.base * 4,
  alignItems: 'center',
  maxWidth: theme.breakpoints.sm,
  margin: '0 auto',
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    gap: theme.gutters.base * 7,
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'min-content 1fr',
    gridTemplateAreas: `
    'intro'
    'phase'
    `,
  },
  [`@media (min-height: ${700}px)`]: {
    gap: theme.gutters.base * 5,
  },
}));

export const Intro = styled('div')<{ theme: Theme }>(({ theme }) => ({
  alignSelf: 'start',
  gridArea: 'intro',
  marginTop: theme.gutters.base * 3,
  display: 'grid',
  gap: theme.gutters.base * 2,
  placeItems: 'center',
  position: 'relative',
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    marginTop: 0,
  },
  [`@media (min-height: ${700}px)`]: {
    marginTop: theme.gutters.base * 6,
    marginBottom: theme.gutters.base * 1,
    gap: theme.gutters.base * 3,
  },
}));

export const StyledPhase = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gridArea: 'phase',
}));

export const StepperContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  gridArea: 'stepper',
}));

export type ActionType = 'freeStrategy' | 'paidStrategy' | 'cardAuthorization';

export const Title = styled(Typography)<{ theme: Theme; alex?: number }>(({ theme, alex }) => ({
  ...theme.typography.meta.p1,
  fontFamily: theme.typography.fontFamily[0],
  fontWeight: 700,

  letterSpacing: 0,
  color: alex === 1 ? 'red' : theme.palette.main.dark,
  [`@media (min-width:  ${theme.breakpoints.md}px)`]: {
    ...theme.typography.meta.h3,
  },
}));

export const Subtitle = styled(Typography)<{ theme: Theme }>(({ theme }) => ({
  ...theme.typography.meta.p1,
  fontFamily: theme.typography.fontFamily[0],
  fontWeight: 600,

  color: theme.palette.main.dark,
  [`@media (min-width:  ${theme.breakpoints.md}px)`]: {
    ...theme.typography.meta.h4,
  },
}));

export const SecureWrap = styled('div')<{ theme: Theme }>(({ theme }) => ({
  gridArea: 'secure',
  display: 'flex',
  justifyContent: 'flex-end',
}));

export const Header = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: 0,
  [`@media (min-width:  ${theme.breakpoints.md}px)`]: {
    padding: '0 10%',
  },
}));

export const FormManager: React.FunctionComponent<FormManagerProps> = ({
  currentPhase,
  isLoading,
  appText,
  loadingText,
  stripeAccount,
}) => {
  if (currentPhase === 'confirmation') {
    return <ConfirmationPage {...{}} />;
  }

  return (
    <>
      <Header>
        <Logo {...{ src: '/logo-dark.svg', maxWidth: 65 }} />
        <SecureWrap {...{}}>
          <Secure />
        </SecureWrap>
      </Header>
      <Wrap>
        <StripeErrorDialog />
        {isLoading && <LoadingFullPage {...{ text: loadingText }} />}
        <Intro>
          <Title {...{ variant: 'h1', center: true }}>{appText.title}</Title>
          {appText.notification && <Notification {...{ text: appText.notification }} />}
        </Intro>

        <StyledPhase>
          {currentPhase === 'cardDetails' && <CardDetails {...{ disclaimerText: appText.legal, stripeAccount }} />}
        </StyledPhase>
      </Wrap>
    </>
  );
};

const Observed = observer(FormManager);

const WithStoreConnection = () => {
  const { main } = useStore();

  const {
    currentPhase,
    isLoading,
    loadingText,
    start,
    action,
    amountToCharge,
    appText,
    isCardSubmissionEnabled,
    stripeAccount,
  } = main;

  React.useEffect(() => {
    start();
  }, [start]);

  return (
    <Observed
      {...{
        currentPhase,
        isLoading,
        loadingText,
        action,
        amountToCharge,
        appText,
        isCardSubmissionEnabled,
        stripeAccount
      }}
    />
  );
};

export default observer(WithStoreConnection);
