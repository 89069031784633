import * as React from 'react';
import styled from '@emotion/styled';
import { Theme } from '@marble/theme';
import { Typography } from '@marble/ui-lib';
import { VIcon } from '@marble/icons';

export interface StepperProps {
  steps: StepMeta[];
  currentStep: string;
}

export interface StepMeta {
  key: string;
  label: string;
}

export const Steps = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'start',
  position: 'relative',
  margin: '0 auto',
  boxSizing: 'border-box',
  '&::after': {
    content: '""',
    width: '75%',
    height: 2,
    position: 'absolute',
    top: 19,
    left: 50,
    backgroundColor: theme.palette.main.secondary3,
  },
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    maxWidth: 470,
    '&::after': {
      top: 27,
    },
  },
}));

export const StyledStep = styled('div')<{
  theme: Theme;
}>(({ theme }) => ({
  position: 'relative',
  padding: 0,
  display: 'grid',
  gridTemplateRows: 'min-content min-content',
  gridTemplateColumns: 70,
  justifyItems: 'center',
  cursor: 'default',
  zIndex: 1,
  gap: theme.gutters.base * 1,
  [`@media (min-width: ${theme.breakpoints.xs}px)`]: {
    gridTemplateColumns: 90,
  },
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    gridTemplateColumns: 116,
    gap: theme.gutters.base * 2,
  },
}));

export const Circle = styled('div')<{ theme: Theme; isActive: boolean }>(({ theme, isActive }) => ({
  height: theme.gutters.base * 5,
  width: theme.gutters.base * 5,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.main.secondary1,
  backgroundColor: theme.palette.main.secondary3,
  ...(isActive && {
    color: theme.palette.text.contrast,
    backgroundColor: theme.palette.main.primary,
  }),
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    height: theme.gutters.base * 7,
    width: theme.gutters.base * 7,
  },
}));

export const Number = styled(Typography)<{ theme: Theme }>(({ theme }) => ({
  color: 'inherit',
  ...theme.typography.meta.p1SemiBold,
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    ...theme.typography.meta.subtitle1,
  },
}));

export const Name = styled(Typography)<{ theme: Theme; isActive: boolean }>(({ theme, isActive }) => ({
  color: isActive ? theme.palette.main.dark : theme.palette.main.secondary1,
  textAlign: 'center',
}));

export const Stepper: React.FunctionComponent<StepperProps> = ({ steps, currentStep = 's' }) => {
  const activeStepIndex = steps.findIndex((step) => step.key === currentStep);

  return (
    <Steps>
      {steps.map(({ key, label }, ind) => {
        const isActive = key === currentStep;
        const isCompleted = ind < activeStepIndex;

        return (
          <StyledStep {...{ key, isActive }}>
            <Circle {...{ isActive }}>
              <Number {...{ component: 'span' }}>
                {isCompleted ? <VIcon {...{ color: 'secondary0' }} /> : ind + 1}
              </Number>
            </Circle>
            <Name {...{ component: 'span', isActive, variant: 'p1SemiBold' }}>{label}</Name>
          </StyledStep>
        );
      })}
    </Steps>
  );
};

export default Stepper;
