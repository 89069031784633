import { CSS } from './';

export const flex: CSS = {
  display: 'flex',
};

// /**
//  * First center refers to JustifyContent
//  * Second refers to AlignItems
//  */
export const CenterCenter: CSS = {
  ...flex,
  justifyContent: 'center',
  alignItems: 'center',
};

export const CenterStretch: CSS = {
  ...flex,
  justifyContent: 'center',
};

export const CenterStart: CSS = {
  ...flex,
  alignItems: 'start',
  justifyContent: 'center',
};

export const StartCenter: CSS = {
  ...flex,
  justifyContent: 'flex-start',
  alignItems: 'center',
};

export const EndCenter: CSS = {
  ...flex,
  justifyContent: 'flex-end',
  alignItems: 'center',
};

export const BetweenCenter: CSS = {
  ...flex,
  justifyContent: 'space-between',
  alignItems: 'center',
};
