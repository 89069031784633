import * as React from 'react';

import styled from '@emotion/styled';
import { Theme, blow } from '@marble/theme';
import { keyframes } from '@emotion/core';
// import { fadeInAnimation } from "../styling/animations";

type MarkerType = 'checkmark' | 'circle';

export interface RadioProps {
  value: string | number;
  // value: Pick<HTMLInputElement, 'value'>;
  label?: string;
  bold?: boolean;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  markerType?: MarkerType;
}

interface CheckboxProps extends Omit<RadioProps, 'name'> {
  /**
   * Reverses the visible state of the checkbox
   * Used when the value that is presented in checkbox
   * needs to be reversed in the model
   */
  reverseDisplay?: boolean;
}

export const FormGroup = styled('div')<{ theme?: Theme; row?: boolean }>(({ theme, row }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  ...(row && {
    flexDirection: 'row',
  }),
}));

// const LabelTypo: React.FunctionComponent = ({ children }) => (
//   <Typography {...{ variant: 'body1' }}>{children}</Typography>
// );

export const Label = styled('label')<{ theme?: Theme; bold?: boolean }>(({ theme, bold }) => ({
  ...theme.typography.meta.caption,
  color: theme.palette.main.secondary0,
  ...(bold && { fontWeight: 600 }),
}));

export const Base = styled('div')<{ theme?: Theme }>(({ theme }) => ({
  position: 'relative',
  display: 'inline-flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: theme.gutters.base,
  // width: '100%',
  cursor: 'pointer',
  '& label': {
    marginLeft: theme.gutters.base,
    marginRight: theme.gutters.base,
  },
}));

export const CheckboxInput = styled('input')<{ theme?: Theme }>(({ theme }) => ({
  background: 'transparent',
  cursor: 'inherit',
  margin: 0,
  opacity: 0,
  ...blow.absolute,
  width: '100%',
  height: '100%',
}));
export const CheckboxMark = styled('div')<{
  theme?: Theme;
  checked: boolean;
  circle?: boolean;
}>(({ theme, checked, circle }) => ({
  // backgroundColor: checked ? theme.palette.main.primary : theme.palette.indicators.success,
  backgroundColor: checked ? theme.palette.text.contrast : 'transparent',
  color: theme.palette.text.contrast,
  borderRadius: '50%',
  border: `1px solid ${theme.palette.main.secondary1}`,
  padding: theme.gutters.base / 4 + 1,
  boxSizing: 'border-box',
  width: theme.gutters.base * 2,
  height: theme.gutters.base * 2,
  flex: '0 0 auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledCheckboxMarkLetter = styled(CheckboxMark)<{
  theme?: Theme;
  checked: boolean;
  circle?: boolean;
}>(({ theme, checked, circle }) => ({
  fontSize: 14,
  color: checked ? theme.palette.text.contrast : theme.palette.text.main,
  backgroundColor: checked ? theme.palette.main.primary : 'transparent',
  width: 26,
  height: 26,
}));

export const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const CheckboxInnerCircle = styled('div')<{ theme?: Theme }>(({ theme }) => ({
  height: theme.gutters.base,
  width: theme.gutters.base,
  backgroundColor: theme.palette.main.secondary1,
  borderRadius: '50%',
  animation: `${fadeInAnimation} 0.2s ease-in-out`,
}));

export const Marker: React.FunctionComponent<Pick<RadioProps, 'markerType'>> = ({ markerType }) => {
  return <CheckboxInnerCircle />;
};

export const CheckboxLetter: React.FunctionComponent<Omit<RadioProps, 'name'> & { label: string }> = ({
  label,
  bold,
  value,
  checked,
  onChange,
}) => {
  return (
    <Base {...{}}>
      <StyledCheckboxMarkLetter {...{ checked, circle: true }}>{label[0].toUpperCase()}</StyledCheckboxMarkLetter>
      <CheckboxInput
        {...{
          type: 'checkbox',
          value,
          onChange: (e) => {
            onChange(e);
          },
          checked,
        }}
      />
    </Base>
  );
};

export const Checkbox: React.FunctionComponent<CheckboxProps> = ({
  label,
  value,
  checked,
  reverseDisplay,
  onChange,
}) => {
  const displayChecked = reverseDisplay ? !checked : checked;
  return (
    <Base {...{}}>
      {/*<CheckboxMark {...{ checked: displayChecked }}>{displayChecked ? <CheckmarkIcon /> : ''}</CheckboxMark>*/}
      <CheckboxMark {...{ checked: displayChecked }}>{displayChecked ? <div>X</div> : ''}</CheckboxMark>
      <CheckboxInput
        {...{
          type: 'checkbox',
          value,
          onChange: (e) => {
            onChange(e);
          },
          checked,
        }}
      />
      {label && <Label>{label}</Label>}
    </Base>
  );
};
export const Radio: React.FunctionComponent<RadioProps> = ({
  name,
  checked,
  label,
  bold,
  value,
  onChange,
  markerType,
}) => {
  return (
    <Base {...{}}>
      <CheckboxMark {...{ checked, circle: true }}>{checked ? <Marker {...{ markerType }} /> : ''}</CheckboxMark>
      <CheckboxInput
        {...{
          type: 'radio',
          value,
          name,
          onChange: (e) => {
            onChange(e);
          },
          checked,
        }}
      />

      {label && <Label {...{ bold }}>{label}</Label>}
    </Base>
  );
};

export default Radio;
