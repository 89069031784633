import * as React from 'react';
import styled from '@emotion/styled';
import { Theme } from '@marble/theme';
import { Typography } from '@marble/ui-lib';

export interface LoadingProps {
  text?: string;
}

const Img = styled('img')<{ theme: Theme }>(() => ({
  display: 'block',
  width: '100%',
  height: 'auto',
  maxWidth: 300,
  margin: '0 auto',
}));

export const Wrap = styled('div')<{ theme: Theme; position?: 'fixed' | 'absolute' }>(
  ({ theme, position = 'absolute' }) => ({
    backgroundColor: 'rgba(249, 253, 254,0.88)',
    position,
    zIndex: 1000,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  }),
);

export const Loading: React.FunctionComponent<LoadingProps> = () => {
  return <Img {...{ src: '/loader.svg' }} />;
};

export const LoadingAbsolute: React.FunctionComponent<LoadingProps> = ({ text }) => {
  return (
    <Wrap>
      <div>
        <Loading />
        {text && <Typography>{text}</Typography>}
      </div>
    </Wrap>
  );
};

export const LoadingFullPage: React.FunctionComponent<LoadingProps> = ({ text }) => {
  return (
    <Wrap {...{ position: 'fixed' }}>
      <Loading />
      {text && <Typography {...{ bolder: true }}>{text}</Typography>}
    </Wrap>
  );
};

export default Loading;
