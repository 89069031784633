import { Typography } from "@marble/ui-lib";
import * as React from "react";
import styled from "@emotion/styled";
import { Theme } from "@marble/theme";
import { wave, successPurple } from "@marble/icons";
import { observer } from "mobx-react";
import { useStore } from "../../stores/setupContext";

export interface ConfirmationPageProps {
  title: string;
  text: string;
}

export const Container = styled("div")<{ theme: Theme }>(({ theme }) => ({
  maxWidth: theme.breakpoints.sm,
  margin: "0 auto",
}));

export const Wave = styled("img")<{ theme: Theme }>(({ theme }) => ({
  display: "block",
  margin: "0 auto",
  width: "100%",
}));

export const Success = styled("img")<{ theme: Theme }>(({ theme }) => ({
  display: "block",
  margin: "0 auto",
  width: theme.gutters.base * 4,
  height: theme.gutters.base * 4,
  marginBottom: theme.gutters.base * 4,
}));

export const ConfirmationPage: React.FunctionComponent<ConfirmationPageProps> =
  ({ title, text }) => {
    return (
      <>
        <Container>
          <Success {...{ src: successPurple }} />
          <Typography {...{ variant: "h3", center: true, bolder: true }}>
            {title}
          </Typography>

          <Typography {...{ variant: "h4", center: true, bold: true }}>
            {text}
          </Typography>
        </Container>
        <Wave {...{ src: wave }} />
      </>
    );
  };

const Observed = observer(ConfirmationPage);

const WithStoreConnection = () => {
  const { main } = useStore();

  const { confirmationText } = main;

  return <Observed {...{ ...confirmationText }} />;
};

export default observer(WithStoreConnection);
