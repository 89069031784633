import * as React from 'react';
import styled from '@emotion/styled';

import { ColorKey, colorSelect, Theme, TypographyVariant } from '@marble/theme';

export interface TypographyProps {
  /**
   * How would you like it to render? Please note, that the default is `p` unless stated otherwise.
   * If you need h1, passing only the variant is not enough.
   * @default 'p'
   */
  component?: keyof HTMLElementTagNameMap;
  /**
   * @default body1
   */
  variant?: TypographyVariant;

  /**
   * sets font-weight 600
   * @default false
   */
  bold?: boolean;

  /**
   * sets font-weight 700
   * @default false
   */
  bolder?: boolean;

  /**
   * If true, no margins will be applied
   * @default false
   */
  noMargin?: boolean;

  /**
   * Align text to center
   */
  center?: boolean;

  /**
   * Imagine you'd like to add some opacity to the text.
   * @default undefined
   */
  textOpacity?: number;

  /**
   * In case you would like to override the color of the text.
   * @default main
   */
  color?: ColorKey;

  /**
   * Which font family to use from the array
   * @default 0
   */
  fontFamilyIndex?: number;

  uppercase?: boolean;
  margin?: React.CSSProperties['margin'];
  marginTop?: React.CSSProperties['marginTop'];
  marginBottom?: React.CSSProperties['marginBottom'];

  children?: React.ReactNode;

  className?: string;
}

const Typo = styled('p')<
  { theme?: Theme } & Pick<
    TypographyProps,
    | 'fontFamilyIndex'
    | 'center'
    | 'noMargin'
    | 'variant'
    | 'bold'
    | 'bolder'
    | 'textOpacity'
    | 'color'
    | 'margin'
    | 'marginBottom'
    | 'marginTop'
    | 'uppercase'
  >
>(
  ({
    theme,
    variant = 'p1',
    bold = false,
    bolder = false,
    noMargin = false,
    center = false,
    textOpacity,
    color = 'dark',
    fontFamilyIndex = 0,
    margin,
    marginBottom,
    marginTop,
    uppercase = false,
  }) => {
    return {
      fontFamily: theme.typography.fontFamily[fontFamilyIndex],
      //   color: isTextColor(color) ? theme.palette.text[color] : theme.palette.indicators[color],
      ...(theme.typography?.meta[variant] ?? {}),
      margin: noMargin ? 0 : 'initial',
      textAlign: center ? 'center' : 'initial',
      color: colorSelect(color),
      ...(uppercase && { textTransform: 'uppercase' }),
      ...(textOpacity && { opacity: textOpacity }),
      ...(bold && { fontWeight: 600 }),
      ...(bolder && { fontWeight: 700 }),
      ...(margin && { margin }),
      ...(marginBottom && { marginBottom }),
      ...(marginTop && { marginTop }),
    };
  },
);

export const Typography: React.FunctionComponent<TypographyProps> = ({
  component = 'p',
  variant = 'p1',
  bold,
  textOpacity,
  uppercase,
  children,
  ...rest
}) => {
  return <Typo {...{ as: component, variant, bold, textOpacity, uppercase, ...rest }}>{children}</Typo>;
};

export default Typography;
