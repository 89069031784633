import * as React from 'react';
import styled from '@emotion/styled';
import { Theme } from '@marble/theme';

export interface TextFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  gridArea?: string;
  placeholder?: string;
  type?: 'text' | 'textarea';
}

const StyledTextField = styled('input')<{ theme: Theme; gridArea?: TextFieldProps['gridArea'] }>(
  ({ theme, gridArea }) => ({
    boxSizing: 'border-box',
    boxShadow: 'none',
    fontSize: 16,
    fontFamily: theme.typography.fontFamily[0],
    fontWeight: 600,
    paddingBottom: theme.gutters.base * 1,
    display: 'block',
    color: theme.palette.main.dark,
    backgroundColor: 'transparent',
    border: 'none',
    borderBottom: `1px solid ${theme.palette.main.secondary1}`,
    outline: 'none',
    width: '100%',
    borderRadius: 0,
    WebkitAppearance: 'none',
    '&::placeholder': {
      color: theme.palette.main.secondary1,
    },
    '&:focus': {
      borderBottom: `1px solid ${theme.palette.main.dark}`,
    },
  }),
);

export const TextField: React.FunctionComponent<TextFieldProps> = ({
  value,
  onChange,
  placeholder,
  gridArea,
  type = 'text',
  ...rest
}) => {
  return <StyledTextField {...{ type, value, onChange, placeholder, ...rest, style: { gridArea } }} />;
};

export default TextField;
